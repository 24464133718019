import React, {useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";

import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";

export default function ThankYou({screenWidth}) {
    const [id, setId] = useState('a43e4c');
    
    return <div className='w-screen h-screen'>
        <Header screenWidth={screenWidth}/>
        <div className='flex flex-col items-center justify-center py-28 px-3 cursor-default'>
            <h1 className="text-4xl text-[#333333] mb-5">THANK YOU.</h1>
            <p>Thank you for your recent purchase of Epson products.</p>
            <p className="my-1">Your submission of rewards has been well received and will be processed within 21 Working Days.</p>
            <p>Once your submission is approved, your e-Voucher* will be sent to your registered email.</p>
            {/* <div className="mt-7">
                <p>Your unique ID is: <span className="font-bold">{id}</span></p>
                <p>Please refer to your email for more details.</p>
            </div> */}
        </div>
        <div className="absolute bottom-0 w-screen">
            <Footer />
        </div>
    </div>
}
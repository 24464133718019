import React, {useEffect, useState} from "react";
import Menu from "../images/menu.svg";
import Close from "../images/close.svg";

export default function Header({screenWidth}) {
    const [openMenu, setOpenMenu] = useState(false);
    const redirectHome = () => {
        window.location.href = '/';
    }

    useEffect(() => {
        if(screenWidth >= 768) {
            setOpenMenu(false);
        }
    }, [screenWidth])

    return <header className="w-full fixed md:relative bg-[#19212b] flex flex-col min-h-12 px-4 md:h-28 md:px-12">
        <div className="w-full h-full flex justify-between items-center">
            <img src={'https://www.epsonrewards.my/wp-content/uploads/2024/06/Epson-Logo-White.png'} alt='Epson Logo' className="h-12 md:h-[60%] " />
            <p className="text-[#d9e3ec] text-lg h-full flex justify-center items-center px-2 cursor-default" >E-Rewards by Epson</p>
        </div>
        {/* {openMenu && <div className="text-lg text-[#d9e3ec] text-start py-1" onClick={redirectHome}>E-Rewards by Epson</div>} */}
    </header>
}
import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Header from "../components/header";
import Footer from "../components/footer";
import UpArrow from '../images/up-arrow.svg';
import { useNavigate } from "react-router-dom";

export default function Home({screenWidth}) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        mobileNum: '',
        name: '',
        // myKad: '',
        email: '',
        // productCategory: '',
        // productModel: '',
        purchasedDate: '',
        purchasedValue: '',
        invoiceNo: '',
        invoiceCopy: null,
        productSerialNo: '',
        productSerialNoImg: null,
        // ecoBearAR: '',
        voucherValue: '',
        tnc: false,
        personalDataPolicy: false,
        marketingComms: false
    });

    const [blurStates, setBlurStates] = useState({
        mobileNum: false,
        // name: false,
        // myKad: false,
        email: false,
        // productCategory: false,
        // productModel: false,
        purchasedDate: false,
        purchasedValue: false,
        invoiceNo: false,
        invoiceCopy: false,
        productSerialNo: false,
        productSerialNoImg: false,

    });
    const [focus, setFocus] = useState('');
    const [canSubmit, setCanSubmit] = useState(false);
    const [backToTopVisible, setBackToTopVisible] = useState(false);
    const scrollContainerRef = useRef(null);
    const [remainingVoucher, setRemainingVoucher] = useState(0);
    const [eventDate, setEventDate] = useState({
        start: '', end: ''
    });
    const [priceRange, setPriceRange] = useState({start: 0, end: 0});
    const [redemptionPeriodEnded, setRedemptionPeriodEnded] = useState(false);
    const [isValidValue, setIsValidValue] = useState(false);
    const [eventCode, setEventCode] = useState('');
    const [disableMobileNum, setDisableMobileNum] = useState(false);
    const [disableInvoiceCopy, setDisableInvoiceCopy] = useState(false);
    const handleSubmit = async () => {
        try {
            const request = await axios.post(`https://api.epsonrewards.my/v1/epson/insertRedemption`, {
                'full_name': formData.name,
                // 'mykad_passport': formData.myKad,
                'contact_no': formData.mobileNum,
                'email': formData.email,
                'serial_number': formData.productSerialNo,
                // 'category': formData.productCategory,
                'model': formData.productModel,
                'purchased_date': formData.purchasedDate,
                'purchased_value': formData.purchasedValue,
                'invoice_no': formData.invoiceNo,
                'invoice_no_img': formData.invoiceCopy,
                'serial_number_img': formData.productSerialNoImg,
                'event_code': eventCode,
                'promo_code': formData.ecoBearAR
            }, {
                headers: {
                    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMjMsImV4cCI6NDg4NzIyODgyNn0.vWUOB7q8p28_b1Yl0sRTPYRoefEZ5iOXhgjDHAhESlI`
                  }
            });
            
            if (request.data.status == 200) {
                navigate('/thank-you');
            } else if (request.data.status == 202) {
                alert("Invalid Redemption! This serial number has already been redeemed.");
            }

        } catch (error) {
            console.log(error);
        }
    }

    const handleSerialNo = (e) => {
        if (e.target.value.length <= 11){
            handleChange('productSerialNo', e.target.value);
        }
    }

    const handleBlur = (field) => {
        setFocus('');
        if (formData[field].trim() == '') {
            setBlurStates({...blurStates, [field]: true});
        } else {
            setBlurStates({...blurStates, [field]: false});
        }
    }

    const handleChange = (field, value) => {
        if (field == 'mobileNum') {
            if (value.length > 11 || isNaN(value)) {
                return;
            }
        } else if (field == 'myKad') {
            if (value.length > 12) {
                return;
            }
        } else if (field == 'purchasedValue') {
            if (isNaN(value)) {
                return;
            } 
            const valCheck = checkValue(value);
            if (valCheck == true) {
                setIsValidValue(valCheck);
            } else {
                setIsValidValue(valCheck);
            }
           
        }
        let temp = {...formData};
        temp[field] = value;
        setFormData(temp);
    }

    const scrollToTop = () => {
        scrollContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };

      function has14DaysPassed(endDate) {
        const end = new Date(endDate);
        
        const now = new Date();
        
        // Calculate the difference in milliseconds
        const timeDifference = now - end;
        
        // Convert milliseconds to days (1000 milliseconds * 60 seconds * 60 minutes * 24 hours)
        const daysPassed = timeDifference / (1000 * 60 * 60 * 24);
        
        return daysPassed >= 14;
      }

      const checkValue = (value) => {
        const val = parseInt(value, 10);
        const start = parseInt(priceRange.start, 10);
        const end = parseInt(priceRange.end, 10);
        if ((formData.purchasedValue != '' && (val >= start && val <= end)) || value == '') {
            return true;
        }

        if (val < start) {
            return 'under';
        } else if (val > end) {
            return 'over';
        }
      }

    useEffect(()=>{
        if (formData.mobileNum.trim() != '' && formData.name.trim() != '' && formData.email.trim() != '' && formData.purchasedDate.trim() != '' && formData.purchasedValue.trim() != '' && formData.invoiceNo.trim() != '' && formData.productSerialNo.trim() != '' && formData.invoiceCopy != null && formData.productSerialNoImg != null && formData.tnc && formData.personalDataPolicy && isValidValue) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [formData]);

    useEffect(() => {
        const handleScroll = () => {
          if (scrollContainerRef.current.scrollTop > 500) {
            setBackToTopVisible(true);
          } else {
            setBackToTopVisible(false);
          }
        };
    
        // Add scroll event listener
        const scrollContainer = scrollContainerRef.current;
        scrollContainer.addEventListener("scroll", handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
            scrollContainer.removeEventListener("scroll", handleScroll);
        };
      }, []);

      useEffect(() => {
        async function decodeData() {
        const data = new URLSearchParams(window.location.search).get('data');
        const dataEncodedUrl = encodeURIComponent(data);

        if (data) {
            const request = await axios.get(`https://api.epsonrewards.my/v1/api-decryption?token=${dataEncodedUrl}`, 
                {
                    headers: {
                      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMjMsImV4cCI6NDg4NzIyODgyNn0.vWUOB7q8p28_b1Yl0sRTPYRoefEZ5iOXhgjDHAhESlI`
                    }
                }
            )
            const dataDecoded = atob(request.data.data.encrypted_details);
            const dataDecodedObj = JSON.parse(dataDecoded);
            console.log(dataDecodedObj);
            return dataDecodedObj;
        }
    }

    async function getData() {
        const data = await decodeData();

        if (data) {
            try {
                const request = await axios.post(`https://api.epsonrewards.my/v1/epson/checkRedemption`,
                    {
                        'model': data.model,
                        'serial_number': data.serial_number,
                        'event_code': data.event_code
                    },
                    {
                        headers: {
                          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMjMsImV4cCI6NDg4NzIyODgyNn0.vWUOB7q8p28_b1Yl0sRTPYRoefEZ5iOXhgjDHAhESlI`
                        }
                    }
                );
                if (request.data.data.redeem_status) {
                    const requestData = request.data.data.result[0];

                    if (has14DaysPassed(requestData.event_end_date)) {
                        setRedemptionPeriodEnded(true);
                        return;
                    }

                    setFormData({...formData, name: data.name, mobileNum: data.contact_no, email: data.email, productSerialNo: data.serial_number, productCategory: requestData.category, productModel: requestData.model, voucherValue: requestData.voucher_amount, purchasedDate: data.purchased_date, purchasedValue: data.purchased_value, invoiceCopy: data.invoice_no_img});

                    setRemainingVoucher(requestData.voucher_quantity - parseInt(requestData.redeemed_count, 10));

                    setEventDate({start: requestData.event_start_date, end: requestData.event_end_date});
                    
                    setPriceRange({start: requestData.redeem_price_range_start
                        , end: requestData.redeem_price_range_end
                    });

                    setEventCode(data.event_code);

                    setDisableMobileNum(data.contact_no == '' ? false : true);

                    setDisableInvoiceCopy(data.invoice_no_img == '' ? false : true);
                }
            } catch (error) {
                console.log(error);
            }
        }

    }
    getData();
      }, []);

    return <div ref={scrollContainerRef} className="h-screen w-screen overflow-y-auto overflow-x-hidden">
        <Header screenWidth={screenWidth} />
        <img src={'https://www.epsonrewards.my/wp-content/uploads/2024/09/YEB-Campaign_Landing_PageFA260924.jpg'} alt='banner' className="w-full mt-12 md:mt-0" />
        { <div id='backToTopBtn' onClick={scrollToTop} className={`fixed rounded-lg bg-black bg-opacity-40 cursor-pointer right-6 bottom-2 size-12 flex items-center justify-center transition-transform duration-500 ease-in-out transform  ${backToTopVisible ? 'translate-y-0' : 'translate-y-[150%]'}`}><img src={UpArrow} className="h-[50%]"/></div>}
        <div id='redemption-form-section'>
            <div className="bg-[#19212b] w-full text-[#d9e3ec] px-[5%] md:pl-[23%] flex flex-col py-8">
                {!redemptionPeriodEnded ? <>
                    <h2 className="text-3xl md:text-4xl font-bold mb-7 flex justify-start">Redemption Form</h2>
                {screenWidth >= 768 ? <p className="text-sm md:text-md flex justify-start text-start">Please fill in all the required field below marked with the asterisk (<span className="text-[#ff0000] align-middle inline-block">*</span>)</p> : <>
                    <p className="text-sm md:text-md flex justify-start text-start">Please fill in all the required field below marked </p><p className="text-start text-sm md:text-md">with the asterisk (<span className="text-[#ff0000] align-middle inline-block">*</span>)</p>
                </>}
                </> : 
                <>
                    <h2 className="text-xl md:text-2xl font-bold mb-7 text-start flex justify-start">THE REDEMPTION FOR E-REWARDS BY EPSON IS NOW CLOSED.</h2>
                    <p className="text-sm md:text-md flex justify-start text-start">Thank you for your support in Epson. Stay tuned for next promotion. </p>
                </>}
                
                
            </div>
            {!redemptionPeriodEnded && 
                <div className="bg-[#fdeb9f] w-full py-12 flex justify-center">
                <div className="bg-white w-[90%] md:w-[70%] p-8 rounded-2xl shadow-lg">
                    <h1 className="text-[#1b98e0] text-md md:text-lg border-t-2 border-[#1b98e0] p-5">Year End Blitz (1 Oct - 31st Dec)</h1>
                    <div className="mt-20">
                    <div className="flex items-start flex-col">
                        <h2 className="mb-10 text-2xl font-semibold ">Personal Information</h2>
                        <div className="w-full text-start">
                            <p className={`font-semibold text-md mb-2 ${focus == 'mobileNum' ? 'text-black' : blurStates.mobileNum ? 'text-[#800000]' : ''}`}>Contact Number (Mobile) <span className="text-[#ff0000]">*</span></p>
                            <p className="text-sm opacity-65 mb-3">(Without "-")</p>
                            <input type='tel' disabled={disableMobileNum} onBlur={()=>handleBlur('mobileNum')} onFocus={()=>setFocus('mobileNum')} className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.mobileNum ? 'border border-[#800000]' : ''}`} value={formData.mobileNum} onChange={e=>handleChange('mobileNum', e.target.value)}></input>
                            {blurStates.mobileNum && !(focus == 'mobileNum') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus == 'name' ? 'text-black' : blurStates.name ? 'text-[#800000]' : ''}`}>Name (as per MyKad / Passport) <span className="text-[#ff0000]">*</span></p>
                            <input disabled={true} type='text' onBlur={()=>handleBlur('name')} onFocus={()=>setFocus('name')} className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.name ? 'border border-[#800000]' : ''}`} value={formData.name} onChange={e=>handleChange('name', e.target.value)}></input>
                            {blurStates.name && !(focus == 'name') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                        {/* <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus == 'myKad' ? 'text-black' : blurStates.myKad ? 'text-[#800000]' : ''}`}>MyKad / Passport Number <span className="text-[#ff0000]">*</span></p>
                            <input type='text' onBlur={()=>handleBlur('myKad')} onFocus={()=>setFocus('myKad')} className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.myKad ? 'border border-[#800000]' : ''}`} value={formData.myKad} onChange={e=>handleChange('myKad', e.target.value)}></input>
                            {blurStates.myKad && !(focus == 'myKad') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div> */}
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus == 'email' ? 'text-black' : blurStates.email ? 'text-[#800000]' : ''}`}>Email <span className="text-[#ff0000]">*</span></p>
                            <input disabled={true} type='email' onBlur={()=>handleBlur('email')} onFocus={()=>setFocus('email')} className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.email ? 'border border-[#800000]' : ''}`} value={formData.email} onChange={e=>handleChange('email', e.target.value)}></input>
                            {blurStates.email && !(focus == 'email') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                    </div>
                    {/* <div className="flex items-start flex-col mt-20">
                        <h2 className="mb-10 text-2xl font-semibold ">Purchase Details</h2>
                        <div className="w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus == 'productCategory' ? 'text-black' : blurStates.productCategory ? 'text-[#800000]' : ''}`}>Product Category <span className="text-[#ff0000]">*</span></p>
                            <input disabled={true} type='text' onBlur={()=>handleBlur('productCategory')} onFocus={()=>setFocus('productCategory')} className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.productCategory ? 'border border-[#800000]' : ''}`} value={formData.productCategory} onChange={e=>handleChange('productCategory', e.target.value)}>
                            </input>
                            {blurStates.productCategory && !(focus == 'productCategory') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                    </div> */}
                    <div className="flex items-start flex-col mt-20">
                        <h2 className="mb-10 text-2xl font-semibold ">Product Details</h2>
                        {/* {
                            formData.productCategory != '' && 
                            <div className="w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus == 'productModel' ? 'text-black' : blurStates.productModel ? 'text-[#800000]' : ''}`}>Product Model <span className="text-[#ff0000]">*</span></p>
                            <input disabled={true} type='text' onBlur={()=>handleBlur('productModel')} onFocus={()=>setFocus('productModel')} className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.productModel ? 'border border-[#800000]' : ''}`} value={formData.productModel} onChange={e=>handleChange('productModel', e.target.value)}>
                            </input>
                            <p className="text-[#ff0000] text-sm mt-1 font-semibold">Remaining Voucher: {remainingVoucher}</p>
                            {blurStates.productModel && !(focus == 'productModel') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                        } */}
                        <div className={`w-full text-start`}>
                            <p className={`font-semibold text-md mb-3 ${focus == 'purchasedDate' ? 'text-black' : blurStates.purchasedDate ? 'text-[#800000]' : ''}`}>Purchased Date <span className="text-[#ff0000]">*</span></p>
                            <input disabled={true} type='date' min={eventDate.start} max={eventDate.end} onBlur={()=>handleBlur('purchasedDate')} onFocus={()=>setFocus('purchasedDate')} className={`cursor-default w-full md:w-[30%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${focus == 'purchasedDate' ? 'text-black' : ''} ${blurStates.purchasedDate ? 'border border-[#800000] text-[#800000]' : ''}`} value={formData.purchasedDate} onChange={e=>handleChange('purchasedDate', e.target.value)}></input>
                            {blurStates.purchasedDate && !(focus == 'purchasedDate') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus == 'purchasedValue' ? 'text-black' : blurStates.purchasedValue ? 'text-[#800000]' : ''}`}>Purchased Value (RM) <span className="text-[#ff0000]">*</span></p>
                            <input type='tel' onBlur={()=>handleBlur('purchasedValue')} onFocus={()=>setFocus('purchasedValue')} className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.purchasedValue ? 'border border-[#800000]' : ''}`} value={formData.purchasedValue} onChange={e=>handleChange('purchasedValue', e.target.value)}></input>
                            {blurStates.purchasedValue && focus != 'purchasedValue' && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}

                            {!(isValidValue == true) && formData.purchasedValue != '' && <p className="text-[#ff0000] font-semibold text-sm mt-2">{isValidValue == 'under' ? 'Purchased value is under the minimum value.' : 'Purchased value is over the maximum value.'}</p>}
                        </div>
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus == 'invoiceNo' ? 'text-black' : blurStates.invoiceNo ? 'text-[#800000]' : ''}`}>Invoice No <span className="text-[#ff0000]">*</span></p>
                            <input type='text' onBlur={()=>handleBlur('invoiceNo')} onFocus={()=>setFocus('invoiceNo')} className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.invoiceNo ? 'border border-[#800000]' : ''}`} value={formData.invoiceNo} onChange={e=>handleChange('invoiceNo', e.target.value)}></input>
                            {blurStates.invoiceNo && focus != 'invoiceNo' && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus == 'invoiceCopy' ? 'text-black' : blurStates.invoiceCopy ? 'text-[#800000]' : ''}`}>Invoice Copy <span className="text-[#ff0000]">*</span></p>
                            <div className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow flex items-center px-3 text-md`}>
                            <input type='file' accept='.jpg,.png,.pdf' disabled={disableInvoiceCopy} onChange={e=>handleChange('invoiceCopy', e.target.value)}></input>
                            </div>
                            <p className="opacity-65 text-sm mt-3">(JPG / PNG / PDF). File size : Maximum 8mb</p>
                            <p className="opacity-65 text-sm">*Kindly ensure that the invoice attached is clear and legible.</p>
                        </div>
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus == 'productSerialNo' ? 'text-black' : blurStates.productSerialNo ? 'text-[#800000]' : ''}`}>Product Serial Number <span className="text-[#ff0000]">*</span></p>
                            <input disabled={true} type='tel' onBlur={()=>handleBlur('productSerialNo')} onFocus={()=>setFocus('productSerialNo')} className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.productSerialNo ? 'border border-[#800000]' : ''}`} value={formData.productSerialNo} onChange={e=>handleSerialNo(e)}></input>
                            <p className="opacity-65 mt-2 text-sm">{formData.productSerialNo.length} of 11 max characters</p>
                        </div>
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3`}>Product Serial Number on device <span className="text-[#ff0000]">*</span></p>
                            <div className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow flex items-center px-3 text-md ${blurStates.productSerialNoImg ? 'border border-[#800000]' : ''}`}>
                            <input type='file' accept='.jpg,.png,.pdf' value={formData.productSerialNoImg} onChange={e=>handleChange('productSerialNoImg', e.target.value)}></input>
                            </div>
                            <p className="opacity-65 text-sm mt-3">Please <a className="text-[#1b98e0]" href="https://www.epsonrewards.my/wp-content/uploads/2020/09/sample.jpeg" target='_blank'>click here</a> for sample of serial number image</p>
                            <p className="opacity-65 text-sm">(JPG / PNG / PDF). File size : Maximum 2mb</p>
                        </div>
                        {/* <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3`}>Eco Bear AR Promo Code (optional)</p>
                            <input type='text' className={`w-full md:w-[50%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md`} value={formData.ecoBearAR} onChange={e=>handleChange('ecoBearAR', e.target.value)}></input>
                        </div> */}
                        <div className="mt-5 w-full text-start">
                            <p className="font-semibold text-md mb-1">Redemption</p>
                            <p className="text-md">Touch 'n Go e-voucher (RM {formData.voucherValue})</p>
                        </div>
                    </div>
                    <div className="mt-5 w-full text-start">
                    <p className={`font-semibold text-md mb-1 ${(blurStates.tnc && !formData.tnc) || (blurStates.personalDataPolicy && !formData.personalDataPolicy) ? 'text-[#800000]' : ''}`}>Terms & Conditions <span className="text-[#800000]">*</span></p>
                        <div className="flex text-md items-center">
                            <input onChange={e=>{handleChange('tnc', !formData.tnc); blurStates.tnc = true}} checked={formData.tnc} type='checkbox' className="size-3"/><p className={`text-sm md:text-md ml-2 ${blurStates.tnc && !formData.tnc ? 'text-[#800000]' : ''}`}><span className="text-[#ff0000]">*</span>I agree to the campaign <a target="_blank" href='https://www.epsonrewards.my/wp-content/uploads/2024/10/Epson_Year-End-Blitz_2024_TCs_Official_Website.pdf' className="text-[#1b98e0]">Terms & Conditions</a></p>
                        </div>
                        <div className="flex text-md items-center mt-3">
                            <input onChange={e=>{handleChange('personalDataPolicy', !formData.personalDataPolicy); blurStates.personalDataPolicy = true}} type='checkbox' checked={formData.personalDataPolicy} className="size-4"/><p className={`text-sm md:text-md ml-2 ${blurStates.personalDataPolicy && !formData.personalDataPolicy ? 'text-[#800000]' : ''}`}><span className="text-[#ff0000]">*</span>Yes, I agree that Epson may collect, use, disclose and/or transfer my personal data in accordance with Epson's Personal Data Policy,
                            available at <a target="_blank" href='https://www.epson.com.my/personaldatapolicy' className="text-[#1b98e0] text-xs md:text-sm"> https://www.epson.com.my/personaldatapolicy</a></p>
                        </div>
                        <div className="flex text-md items-center mt-3">
                            <input onChange={e=>handleChange('marketingComms', !formData.marketingComms)} checked={formData.marketingComms} type='checkbox' className="size-3"/><p className="text-sm md:text-md ml-2">Yes, I agree to receive marketing related communications about Epson products, services and campaigns.</p>
                        </div>
                        <button className={`text-white text-md font-semibold rounded-lg w-full md:w-32 py-4 mt-8 shadow-lg ${!canSubmit ? 'bg-black opacity-30 cursor-default' : 'bg-[#e95095] cursor-pointer hover:bg-[#9966CC]'}`} onClick={handleSubmit} disabled={!canSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            }
            
            <Footer />
        </div>
    </div>
}
import { BrowserRouter, Routes, Route, useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Home from "./pages/home";
import ThankYou from "./pages/ThankYou";
import Dummy from "./pages/dummy";
import './App.css';

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);   
 // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);   

    };
  }, []);

  return (
    <div className="App overflow-x-hidden">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home screenWidth={screenWidth}/>} />
          <Route path="/thank-you" element={<ThankYou screenWidth={screenWidth} />} />
          <Route path="/dummy" element={<Dummy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
